const {
	href, origin, pathname, search
} = window.location;

// Map of old URLs to new URLs
const redirects = new Map([
	["/adam", "/de/Mitterndorf/Adam"],
	["/adam-tauplitz", "/de/Tauplitz/Adam.Tauplitz"],
	["/ak-alpenroos", "/de/Mitterndorf/Alpenroos-AK"],
	["/ak-edelweiss", "/de/Mitterndorf/Edelweiss-AK"],
	["/ak-gentiaan", "/de/Mitterndorf/Gentiaan-AK"],
	["/ak-narcis", "/de/Mitterndorf/Narcis-AK"],
	["/ak-zilverkruid", "/de/Mitterndorf/Zilverkruid-AK"],
	["/almblick", "/de/Sonnenalm/Almblick"],
	["/almkogel", "/de/Tauplitz/Almkogel"],
	["/almrausch", "/de/Tauplitz/Almrausch"],
	["/alp-tau5", "/de/Tauplitz/Alpine.Tauplitz.TOP5"],
	["/alpenglueck", "/de/Tauplitz/Alpenglueck"],
	["/alpenoase", "/de/Tauplitz/Alpenoase"],
	["/alpenpanorama", "/de/Mitterndorf/Alpenpanorama"],
	["/alpenrock", "/de/Mitterndorf/Alpenrock"],
	["/alpine", "/de/s"],
	["/alterkindergarten", "/de/s"],
	["/alterkindergarten-de", "/de/s"],
	["/alterkindergarten-en", "/de/s"],
	["/ambiente", "/de/Sonnenalm/Ambiente"],
	["/andre", "/de/Sonnenalm/Andre"],
	["/ausseerland", "/de/Sonnenalm/Ausseerland"],
	["/bergkristall", "/de/Tauplitz/Bergkristall"],
	["/bergzeit", "/de/Oeblarn/Bergzeit"],
	["/bernhard", "/de/Sonnenalm/Bernhard"],
	["/bettina", "/de/Tauplitz/Bettina"],
	["/buehemirla", "/de/Altaussee/Buehemirla"],
	["/carpediem", "/de/Sonnenalm/Carpe.Diem"],
	["/central", "/de/Mitterndorf/Central"],
	["/charlotte", "/de/Sonnenalm/Charlotte"],
	["/christina", "/de/Tauplitz/Christina"],
	["/dolcevita", "/de/Mitterndorf/Dolce.Vita"],
	["/dominique", "/de/Sonnenalm/Dominique"],
	["/en/adam", "/en/Mitterndorf/Adam"],
	["/en/adam-tauplitz", "/en/Tauplitz/Adam.Tauplitz"],
	["/en/ak-alpenroos", "/en/Mitterndorf/Alpenroos-AK"],
	["/en/ak-edelweiss", "/en/Mitterndorf/Edelweiss-AK"],
	["en/ak-gentiaan", "/en/Mitterndorf/Gentiaan-AK"],
	["/en/ak-narcis", "/en/Mitterndorf/Narcis-AK"],
	["/en/ak-zilverkruid", "/en/Mitterndorf/Zilverkruid-AK"],
	["/en/almblick", "/en/Sonnenalm/Almblick"],
	["/en/almkogel", "/en/Tauplitz/Almkogel"],
	["/en/almrausch", "/en/Tauplitz/Almrausch"],
	["/en/alp-tau5", "/en/Tauplitz/Alpine.Tauplitz.TOP5"],
	["/en/alpenglueck", "/en/Tauplitz/Alpenglueck"],
	["/en/alpenoase", "/en/Tauplitz/Alpenoase"],
	["/en/alpenpanorama", "/en/Mitterndorf/Alpenpanorama"],
	["/en/alpenrock", "/en/Mitterndorf/Alpenrock"],
	["/en/alpine", "/en/s"],
	["/en/ambiente", "/en/Sonnenalm/Ambiente"],
	["/en/andre", "/en/Sonnenalm/Andre"],
	["/en/ausseerland", "/en/Sonnenalm/Ausseerland"],
	["/en/bergkristall", "/en/Tauplitz/Bergkristall"],
	["/en/bergzeit", "/en/Oeblarn/Bergzeit"],
	["/en/bernhard", "/en/Sonnenalm/Bernhard"],
	["/en/bettina", "/en/Tauplitz/Bettina"],
	["/en/buehemirla", "/en/Altaussee/Buehemirla"],
	["/en/carpediem", "/en/Sonnenalm/Carpe.Diem"],
	["/en/central", "/en/Mitterndorf/Central"],
	["/en/charlotte", "/en/Sonnenalm/Charlotte"],
	["/en/christina", "/en/Tauplitz/Christina"],
	["/en/dolcevita", "/en/Mitterndorf/Dolce.Vita"],
	["/en/dominique", "/en/Sonnenalm/Dominique"],
	["/en/enzian", "/en/Sonnenalm/Enzian"],
	["/en/erika", "/en/Sonnenalm/Erika"],
	["/en/great-time", "/en/s"],
	["/en/grimmingblick", "/en/Tauplitz/Grimmingblick"],
	["/en/grimminglounge", "/en/Zauchen/Grimminglounge"],
	["/en/grimmingsuite", "/en/Tauplitz/Grimmingsuite"],
	["/en/irmgard", "/en/Sonnenalm/Irmgard"],
	["/en/kammspitze", "/en/Sonnenalm/Kammspitze"],
	["/en/katalin", "/en/Mitterndorf/Katalin"],
	["/en/kindergarten", "/en/s"],
	["/en/klampfner", "/en/Altaussee/Klampfner"],
	["/en/klampfnerguetl", "/en/s"],
	["/en/leonore", "/en/Mitterndorf/Leonore"],
	["/en/marina", "/en/Mitterndorf/Marina"],
	["/en/mary", "/en/Tauplitz/Mary"],
	["/en/montania", "/en/s"],
	["/en/nahdran", "/en/Neuhofen/Nah.dran"],
	["/en/pan-tau", "/en/s"],
	["/en/panoramablick", "/en/Neuhofen/Panoramablick"],
	["/en/petra", "/en/Neuhofen/Petra"],
	["/en/regina", "/en/Sonnenalm/Regina"],
	["/en/salzkammergut", "/en/Tauplitz/Salzkammergut"],
	["/en/sandlweber", "/en/s"],
	["/en/sandlweber1", "/en/Obersdorf/Sandlweber.1"],
	["/en/sandlweber10", "/en/s"],
	["/en/sandlweber2", "/en/Obersdorf/Sandlweber.2"],
	["/en/sandlweber3", "/en/s"],
	["/en/sandlweber4-5", "/en/Obersdorf/Sandlweber.4-5"],
	["/en/sandlweber6", "/en/Obersdorf/Sandlweber.6"],
	["/en/sandlweber8", "/en/s"],
	["/en/sandra", "/en/Tauplitz/Sandra"],
	["/en/seeidylle", "/en/Fischerndorf/Seeidylle"],
	["/en/sonnenfreude", "/en/Mitterndorf/Sonnenfreude"],
	["/en/sunside", "/en/Mitterndorf/Sunside.1"],
	["/en/sunside1", "/en/Mitterndorf/Sunside.1"],
	["/en/sunside2", "/en/Mitterndorf/Sunside.2"],
	["/en/sunside21", "/en/Mitterndorf/Sunside.2"],
	["/en/sunside3", "/en/Mitterndorf/Sunside.3"],
	["/en/toni", "/en/Sonnenalm/Toni"],
	["/en/urlaubstraum", "/en/Sonnenalm/Urlaubstraum"],
	["/en/waldblume", "/en/s"],
	["/enzian", "/de/Sonnenalm/Enzian"],
	["/erika", "/de/Sonnenalm/Erika"],
	["/ge", "/de/s"],
	["/ge/adam", "/de/Mitterndorf/Adam"],
	["/ge/adam-tauplitz", "/de/Tauplitz/Adam.Tauplitz"],
	["/ge/ak-alpenroos", "/de/Mitterndorf/Alpenroos-AK"],
	["/ge/ak-edelweiss", "/de/Mitterndorf/Edelweiss-AK"],
	["/ge/ak-gentiaan", "/de/Mitterndorf/Gentiaan-AK"],
	["/ge/ak-narcis", "/de/Mitterndorf/Narcis-AK"],
	["/ge/ak-zilverkruid", "/de/Mitterndorf/Zilverkruid-AK"],
	["/ge/almblick", "/de/Sonnenalm/Almblick"],
	["/ge/almkogel", "/de/Tauplitz/Almkogel"],
	["/ge/almrausch", "/de/Tauplitz/Almrausch"],
	["/ge/alp-tau5", "/de/Tauplitz/Alpine.Tauplitz.TOP5"],
	["/ge/alpenglueck", "/de/Tauplitz/Alpenglueck"],
	["/ge/alpenoase", "/de/Tauplitz/Alpenoase"],
	["/ge/alpenpanorama", "/de/Mitterndorf/Alpenpanorama"],
	["/ge/alpenrock", "/de/Mitterndorf/Alpenrock"],
	["/ge/alpine", "/de/s"],
	["/ge/ambiente", "/de/Sonnenalm/Ambiente"],
	["/ge/andre", "/de/Sonnenalm/Andre"],
	["/ge/ausseerland", "/de/Sonnenalm/Ausseerland"],
	["/ge/bergkristall", "/de/Tauplitz/Bergkristall"],
	["/ge/bergzeit", "/de/Oeblarn/Bergzeit"],
	["/ge/bernhard", "/de/Sonnenalm/Bernhard"],
	["/ge/bettina", "/de/Tauplitz/Bettina"],
	["/ge/buehemirla", "/de/Altaussee/Buehemirla"],
	["/ge/carpediem", "/de/Sonnenalm/Carpe.Diem"],
	["/ge/central", "/de/Mitterndorf/Central"],
	["/ge/charlotte", "/de/Sonnenalm/Charlotte"],
	["/ge/christina", "/de/Tauplitz/Christina"],
	["/ge/dolcevita", "/de/Mitterndorf/Dolce.Vita"],
	["/ge/dominique", "/de/Sonnenalm/Dominique"],
	["/ge/enzian", "/de/Sonnenalm/Enzian"],
	["/ge/erika", "/de/Sonnenalm/Erika"],
	["/ge/great-time", "/de/s"],
	["/ge/grimmingblick", "/de/Tauplitz/Grimmingblick"],
	["/ge/grimminglounge", "/de/Zauchen/Grimminglounge"],
	["/ge/grimmingsuite", "/de/Tauplitz/Grimmingsuite"],
	["/ge/irmgard", "/de/Sonnenalm/Irmgard"],
	["/ge/kammspitze", "/de/Sonnenalm/Kammspitze"],
	["/ge/katalin", "/de/Mitterndorf/Katalin"],
	["/ge/kindergarten", "/de/s"],
	["/ge/klampfner", "/de/Altaussee/Klampfner"],
	["/ge/klampfnerguetl", "/de/s"],
	["/ge/leonore", "/de/Mitterndorf/Leonore"],
	["/ge/marina", "/de/Mitterndorf/Marina"],
	["/ge/mary", "/de/Tauplitz/Mary"],
	["/ge/montania", "/de/s"],
	["/ge/nahdran", "/de/Neuhofen/Nah.dran"],
	["/ge/pan-tau", "/de/s"],
	["/ge/panoramablick", "/de/Neuhofen/Panoramablick"],
	["/ge/petra", "/de/Neuhofen/Petra"],
	["/ge/regina", "/de/Sonnenalm/Regina"],
	["/ge/salzkammergut", "/de/Tauplitz/Salzkammergut"],
	["/ge/sandlweber", "/de/s"],
	["/ge/sandlweber1", "/de/Obersdorf/Sandlweber.1"],
	["/ge/sandlweber10", "/de/s"],
	["/ge/sandlweber2", "/de/Obersdorf/Sandlweber.2"],
	["/ge/sandlweber3", "/de/s"],
	["/ge/sandlweber4-5", "/de/Obersdorf/Sandlweber.4-5"],
	["/ge/sandlweber6", "/de/Obersdorf/Sandlweber.6"],
	["/ge/sandlweber8", "/de/s"],
	["/ge/sandra", "/de/Tauplitz/Sandra"],
	["/ge/seeidylle", "/de/Fischerndorf/Seeidylle"],
	["/ge/sonnenfreude", "/de/Mitterndorf/Sonnenfreude"],
	["/ge/sunside", "/de/Mitterndorf/Sunside.1"],
	["/ge/sunside1", "/de/Mitterndorf/Sunside.1"],
	["/ge/sunside2", "/de/Mitterndorf/Sunside.2"],
	["/ge/sunside3", "/de/Mitterndorf/Sunside.3"],
	["/ge/toni", "/de/Sonnenalm/Toni"],
	["/ge/urlaubstraum", "/de/Sonnenalm/Urlaubstraum"],
	["/ge/waldblume", "/de/s"],
	["/gesamtpaket", "/de/vermietservice"],
	["/great-time", "/de/s"],
	["/grimmingblick", "/de/Tauplitz/Grimmingblick"],
	["/grimminglounge", "/de/Zauchen/Grimminglounge"],
	["/grimmingsuite", "/de/Tauplitz/Grimmingsuite"],
	["/irmgard", "/de/Sonnenalm/Irmgard"],
	["/kammspitze", "/de/Sonnenalm/Kammspitze"],
	["/karte", "/de/s"],
	["/katalin", "/de/Mitterndorf/Katalin"],
	["/klampfner", "/de/Altaussee/Klampfner"],
	["/klampfnerguetl", "/de/s"],
	["/klampfnerguetl-de", "/de/s"],
	["/klampfnerguetl-en", "/de/s"],
	["/kontakt", "/de/kontakt"],
	["/kulmhof", "/de/s"],
	["/lena", "/de/s"],
	["/leonore", "/de/Mitterndorf/Leonore"],
	["/liste", "/de/s"],
	["/marina", "/de/Mitterndorf/Marina"],
	["/mary", "/de/Tauplitz/Mary"],
	["/montania", "/de/s"],
	["/nahdran", "/de/Neuhofen/Nah.dran"],
	["/nurbuchungen", "/de/s"],
	["/pan-tau", "/de/s"],
	["/panoramablick", "/de/Neuhofen/Panoramablick"],
	["/petra", "/de/Neuhofen/Petra"],
	["/regina", "/de/Sonnenalm/Regina"],
	["/salzkammergut", "/de/Tauplitz/Salzkammergut"],
	["/sandlweber", "/de/s"],
	["/sandlweber-de", "/de/s"],
	["/sandlweber-en", "/de/s"],
	["/sandlweber1", "/de/Obersdorf/Sandlweber.1"],
	["/sandlweber10", "/de/s"],
	["/sandlweber2", "/de/Obersdorf/Sandlweber.2"],
	["/sandlweber3", "/de/s"],
	["/sandlweber4-5", "/de/Obersdorf/Sandlweber.4-5"],
	["/sandlweber6", "/de/Obersdorf/Sandlweber.6"],
	["/sandlweber8", "/de/s"],
	["/sandra", "/de/Tauplitz/Salzkammergut"],
	["/schluesseluebergabe", "/de/fragen-und-antworten"],
	["/seeidylle", "/de/Fischerndorf/Seeidylle"],
	["/silvia", "/de/s"],
	["/sonnenfreude", "/de/Mitterndorf/Sonnenfreude"],
	["/suche", "/de/s"],
	["/sunside", "/de/Mitterndorf/Sunside.1"],
	["/sunside-de", "/de/Mitterndorf/Sunside.1"],
	["/sunside-en", "/de/Mitterndorf/Sunside.1"],
	["/sunside1", "/de/"],
	["/sunside2", "/de/Mitterndorf/Sunside.2"],
	["/sunside3", "/de/Mitterndorf/Sunside.3"],
	["/toni", "/de/Sonnenalm/Toni"],
	["/urlaubstraum", "/de/Sonnenalm/Urlaubstraum"],
	["/vermieten", "/de/vermietservice"],
	["/vermieterkontakt", "/de/vermietservice"],
	["/waldblume", "/de/s"],
]);

// console.log("pathname", pathname);
// console.log("redirects", redirects);
// console.log("redirects.has(pathname)", redirects.has(pathname));

// Check if the current URL (pathname + search) matches any key in the redirects map
const currentPath = pathname + search;

if (redirects.has(currentPath)) {
	// Redirect to the new path without the search parameters
	location.href = origin + redirects.get(currentPath);
}